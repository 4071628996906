import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import DonwloadForm from "../components/downloadform"

const Download = ({ data, location }) => {


    const siteTitle = data.site.siteMetadata?.title || `Title`

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="お問い合わせ" />
        <div className="post-header">
            <h1>取扱説明書・ソフトウェアダウンロード</h1>
        </div>
        <div className="post-body">
            <p>下記フォーマットにご記入いただき、内容をご確認のうえ、送信してください。</p>
            <DonwloadForm />

        </div>
        </Layout>
    )
}

export default Download

export const downloadQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
    