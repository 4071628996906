import React from "react"
import firebase from "gatsby-plugin-firebase"
// import firebase from "firebase/compat/app"

export default class DonwloadForm extends React.Component {
  constructor() {
    super()
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      isSubmitted: false,
      categories: [],
      products: [],
      selectedCategory: '選択してください',
      selectedProduct: '選択してください',
      count: 0,
      sendProduct: "",
      sendAddress: "",
      sendURL: ""
    }
    this.changeCategory = this.changeCategory.bind(this)
    this.changeProduct = this.changeProduct.bind(this)
  }

  componentDidMount() {
    this.setState({
      // マニュアルのない製品はコメントアウト
      categories: [
        {
          name: '監視カメラ', products: [
            { name: 'TDC-107M', url: '/manual/adc107mman.pdf' },
            { name: 'TDC-107MIR', url: '/manual/adc107mirman.pdf' },
            { name: 'TDC-100MP', url: '/manual/tdc-100mp-man.pdf' },
            { name: 'DLC-100MP', url: '/manual/dlc-100mp-man.pdf' },
            { name: 'IDC-1004MIR', url: '/manual/idc-1004m-man.pdf' },
            { name: 'IDC-1002M', url: '/manual/idc-1002m-man.pdf' },
            { name: 'ILC-7002M', url: '/manual/ilc-7002m-man.pdf' },
            { name: 'LC-61MER', url: '/manual/lc61mer_man.pdf' },
            // { name:'IDC-1002M', url:'/manual/adc107mman.pdf'},
            { name: 'ILC-61M2', url: '/manual/ilc61m220150326man.pdf' },
            { name: 'APTZ-104M', url: '/manual/aptz104m_man.pdf' },
            { name: 'CC-72M', url: '/manual/cc72mmanual.pdf' },
            { name: 'MC-30M', url: '/manual/mc30m_man.pdf' },
          ]
        },
        {
          name: 'レコーダー', products: [
            // NVRとHDUモデルのマニュアルについてあとで確認
            { name: 'ANEモデル', url: '/manual/aneman.pdf' },
            { name: 'APMモデル', url: '/manual/apmman.pdf' },
            { name: 'APEモデル', url: '/manual/ape1623sman.pdf' },
            { name: 'ATMモデル', url: '/manual/atmman.pdf' },
            { name: 'ATEモデル', url: '/manual/ateman.pdf' },
            { name: 'XDSモデル', url: '/manual/xds1643man.pdf' },
            { name: 'NVRモデル', url: '/manual/hdu413-813-nvr-man.pdf' },
            { name: 'AHEモデル', url: '/manual/ahemodelman2.pdf' },
            // { name:'AHSモデル', url:'/manual/tdc-107m2'},
            { name: 'HDSモデル', url: '/manual/hdx1643man.pdf' },
            { name: 'HDXモデル', url: '/manual/hdu413-813man.pdf' },
            // { name:'HDUモデル', url:'/manual/hdu413-813man.pdf'},
            { name: 'HDUモデル', url: '/manual/hdu405man.pdf' },
            // { name:'XDEモデル', url:'/manual/apmman.pdf'},
          ]
        },
        {
          name: '画像伝送装置', products: [
            { name: 'LAN-HD264ED', url: '/manual/lanhd264edman.pdf' },
            { name: 'LAN-HD264E', url: '/manual/lanhd264eman.pdf' },
            { name: 'LAN-HD264D', url: '/manual/lanhd264dman.pdf' },
            { name: 'LAN-NTSC264+', url: '/manual/lanntsc264pman.pdf' },
            { name: 'LAN-NTSC4ch', url: '/manual/lanntsc264a4ch.pdf' },
            { name: 'LAN-HD264', url: '/manual/lanhd264man.pdf' },
          ]
        },
        {
          name: '非接触型測温計', products: [
            { name: 'フェイス＆サーモ', url: '/manual/manual-face-and-thermo.pdf' },
          ]
        },
        {
          name: 'ソフトウェア', products: [
            { name: 'UMS_3.8.2.0', url: '/software/umsclient_3.8.2.0_210713.exe' },
            // { name: 'UMS_3.8.1.6', url: '/software/umsclient_3.8.1.6_200225.exe' },
            { name: 'UMS_3.0.2.9', url: '/software/umsclient_3.0.2.9_110926.exe' },
            { name: 'UMS_M_3.8.1.5', url: '/software/umsmclient_3.8.1.5_210713.exe' },
            // { name: 'UMS_M_3.8.1.1', url: '/software/umsmclient_3.8.1.1_200225.exe' },
            { name: 'UMS_M_3.8.0.7', url: '/software/umsmclient_3.8.0.7_190426.exe' },
            { name: 'TrueManager', url: '/software/True_Manager_Installer.exe' },
            { name: 'TrueFinder', url: '/software/truefinder.exe' },
            { name: 'TrueFinder（取説）', url: '/manual/truefinderman.pdf' },
            { name: 'TVMS', url: '/software/tvms1_06_5710.exe' },
            { name: 'TVMS（取説）', url: '/manual/tvmsman.pdf' },
            { name: 'iUMS＋（取説）', url: '/manual/iUMS_plus_man.pdf' },
          ]
        },
        {
          name: 'ファームウェア（画像伝送装置）', products: [
            { name: 'LAN-HD264ED', url: '/software/tcs3500.v4.709r01-091111.tus' },
            { name: 'LAN-HD264E', url: '/software/V3.907R01.tus' },
            { name: 'LAN-HD264D', url: '/software/TCS1500.V4.908R01-080618.tus' },
            { name: 'LAN-NTSC264+', url: '/software/V1.808R01-T100.tus' },
            { name: 'LAN-NTSC4ch', url: '/software/V3.706R01.tus' },
            { name: 'LAN-HD264', url: '/software/V1.808R01-T100.tus' },
          ]
        },
        {
          name: 'ファームウェア（レコーダー）', products: [
            { name: 'ANE(4ch)', url: '/software/main_ane0409_isec_9.1.0_20220214.bin' },
            { name: 'ANE(8ch)', url: '/software/main_ane0819_isec_9.1.0_20220214.bin' },
            { name: 'ANE(16ch)', url: '/software/main_ane1629_isec_9.1.0_20220214.bin' },
            { name: 'APE(4ch)', url: '/software/main_ape402_isec_9.0.0_20220118.bin' },
            { name: 'APE(8ch)', url: '/software/main_ape815_isec_9.0.0_20220118.bin' },
            { name: 'APE(16ch)', url: '/software/main_ape1623_isec_9.0.0_20220118.bin' },
            { name: 'ATE(4ch)', url: '/software/main_at-0402e_isec_9.0.0_20220203.bin' },
            { name: 'ATE(8ch)', url: '/software/main_at-0815e_isec_9.0.0_20220203.bin' },
            { name: 'ATE(16ch)', url: '/software/main_at-1623e_isec_9.0.0_20220203.bin' },
            { name: 'AHE(4ch)', url: '/software/main_ah-0405e_basic_9.0.0_20220204.bin' },
            { name: 'AHE(8ch)', url: '/software/main_ah-0813e_basic_9.0.0_20220204.bin' },
            { name: 'AHE(16ch)', url: '/software/main_ah-1623e_basic_9.0.0_20220204.bin' },
          ]
        },
        // {name: '周辺機器', products: [
        //   { name:'TDC-107M', url:'/manual/tdc-107m'},
        //   { name:'TDC-107M2', url:'/manual/tdc-107m2'},
        // ]},
      ]
    })
  }

  componentDidUpdate() {
    if (this.state.count < 3) {
      //URLパラメータを文字列で取得(?含む)
      let urlParamStr = window.location.search
      // console.log(urlParamStr)
      if (urlParamStr) {
        //?を除去
        urlParamStr = urlParamStr.substring(1)

        let params = {}

        //urlパラメータをオブジェクトにまとめる
        urlParamStr.split('&').forEach(param => {
          const temp = param.split('=')
          //pramsオブジェクトにパラメータを追加
          params = {
            ...params,
            [temp[0]]: temp[1]
          }
        })
        //category自動入力
        let options = document.querySelectorAll('#category option')
        if (Array.from(options).filter(ele => ele.text === decodeURI(params.category))[0] !== undefined) {
          Array.from(options).filter(ele => ele.text === decodeURI(params.category))[0].selected = true;
          if (this.state.selectedCategory !== decodeURI(params.category)) {
            this.setState({ selectedCategory: decodeURI(params.category) })
            this.setState({ selectedProduct: '選択してください' })
            this.setState({ products: this.state.categories.find(category => category.name === decodeURI(params.category)).products })
          }
          //product自動入力
          let optionsProduct = document.querySelectorAll("#product option")
          if (Array.from(optionsProduct).filter(ele => ele.text === decodeURI(params.product))[0] !== undefined) {
            // console.log(Array.from(optionsProduct).filter(ele => ele.text === decodeURI(params.product))[0])
            Array.from(optionsProduct).filter(ele => ele.text === decodeURI(params.product))[0].selected = true;
            if (this.state.selectedProduct !== decodeURI(params.product)) {
              this.setState({ selectedProduct: decodeURI(params.product) })
            }
          }
        }
      }
      this.state.count++
      // this.setState({ count: this.state.count + 1 })
      // console.log(this.state.count)
      // console.log(this.state.selectedProduct)
    }
  }


  // カテゴリーを変更した時の挙動
  changeCategory(event) {
    this.setState({ selectedCategory: event.target.value })
    this.setState({ selectedProduct: '選択してください' })
    if (event.target.value !== '選択してください') {
      this.setState({ products: this.state.categories.find(category => category.name === event.target.value).products })
    }
  }
  
  // 製品を変更した時の挙動
  changeProduct(event) {
    this.setState({ selectedProduct: event.target.value })
  }
  
  // 送信ボタンを押した時の挙動
  onSubmit(e) {
    // NGワード登録
    const ngwords = [
      "テスト",
      "あ",
    ]

    e.preventDefault()
    
    // NGワード判定
    const result = ngwords.filter(word => e.target.name.value === word )
    const result_b = ngwords.filter(word => e.target.company.value === word )
    if (result.length || result_b.length ) {
      alert("不正な入力を検知しました。")
    } else {
      let data = {}
      if (e.target.category.value === "選択してください" || e.target.product.value === "選択してください") {
        alert("カテゴリーと製品を選択してください。")
      } else {
        // フォームに入力した情報をdataにセットする
        data.category = e.target.category.value
        data.productName = e.target.product.options[e.target.product.selectedIndex].text
        data.product = e.target.product.value
        data.company = e.target.company.value
        data.name = e.target.name.value
        data.email = e.target.email.value
        data.ua = window.navigator.userAgent
        
        // firebaseにデータ送信
        let sendMail = firebase.functions().httpsCallable('sendDownload')
        sendMail(data)

        this.setState({ sendProduct: e.target.product.options[e.target.product.selectedIndex].text })
        this.setState({ sendAddress: e.target.email.value })
        this.setState({ sendURL: e.target.product.value })
        
        // 入力内容をリセット
        // this.setState({ selectedCategory: "選択してください" })
        this.setState({ selectedProduct: "選択してください" })
        // e.target.name.value = ""
        // e.target.email.value = ""
        // e.target.company.value = ""
        e.target.agree.checked = false
        e.target.value = ""
        this.setState({ isSubmitted: true })
      }
    }
  }

  render() {

    let contactForm
    if (this.state.isSubmitted) {
      contactForm = (
        <div className='contact-submit-message'>
          <h3>送信完了</h3>
          <p><a href={"https://isec-download.sakura.tv/download" + this.state.sendURL} rel="noopener noreferrer" target="_blank"><b>こちらのリンク</b></a>から{this.state.sendProduct}の取扱説明書・ソフトウェアをダウンロードできます。</p>
          <br/>
          <p>ダウンロードURLは&nbsp;{this.state.sendAddress}&nbsp;宛にもメール送信済です。</p>
          <p>※メールの到着には<b>30秒～90秒</b>ほどかかります。</p>
          <p>※メールが届かない場合は<b>迷惑メールフォルダ</b>をご確認ください。</p>
        </div>
      )
    } else {
      contactForm = (
        <div className='contact-submit-message'>
        </div>
      )
    }
    return (
      <div className='contact-form'>
        <div id="debug"></div>
        <form onSubmit={this.onSubmit}>
          <div className="dropdown">
            <label htmlFor="category">カテゴリー</label><br />
            <select name="category" id="category" value={this.state.selectedCategory} onChange={this.changeCategory} >
              <option disabled>選択してください</option>
              {this.state.categories.map((e, key) => {
                return <option key={key} >{e.name}</option>
              })}
            </select>
          </div>
          <div className="dropdown">
            <label htmlFor="product">製品</label><br />
            <select name="product" id="product" value={this.state.selectedProduct} onChange={this.changeProduct}>
              <option disabled>選択してください</option>
              {this.state.products.map((e, key) => {
                return <option key={key} value={e.url}>{e.name}</option>
              })}
            </select>
          </div>
          <p>
            <input
              type="text"
              name="company"
              placeholder="会社名（必須）"
              required
            />
          </p>
          <p>
            <input
              type="text"
              name="name"
              placeholder="氏名（必須）"
              required
            />
          </p>
          <p>
            <input
              type="email"
              name="email"
              placeholder="メールアドレス（必須）"
              required
            />
          </p>
          <h4>利用規約</h4>
          <p className="terms-scroll">
            お客様が以下の使用許諾契約に同意する場合にのみ、お客様は取扱説明書及びソフトウェアをダウンロードし、またはこれを使用することができます。<br />
使用許諾契約をよくお読みいただき、記載される条件に同意いただけた場合には、以下のリンクから取扱説明書及びソフトウェアをダウンロードして下さい。<br />
お客様が本契約に同意できない場合には、ダウンロードサービスから取扱説明書及びソフトウェアをダウンロードしないでください。<br />
また、ダウンロードした取扱説明書及びソフトウェアを使用しないでください。<br />
■取扱説明書及びソフトウェア使用許諾契約アイゼック株式会社（以下、弊社といいます）は、お客様が取扱説明書及びソフトウェア使用許諾契約（以下、本契約といいます）に同意する場合にかぎり、
取扱説明書及びソフトウェア（弊社ダウンロードサービスに提供される全ての取扱説明及びソフトウェア（ユーティリティ・ファームウェア・ドライバなどを含む）以下、本取扱説明書及びソフトウェアといいます）の使用を許諾いたします。<br />
第1条　使用許諾<br />
1.弊社は、本契約に規定する条件で、本取扱説明書及びソフトウェアの使用をお客様に非専属的に許諾します。<br />
第2条　知的所有権<br />
1.本取扱説明書及びソフトウェアは、著作権法その他の無体財産権に関する法律ならびに条約によって保護されています。<br />
2.本取扱説明書及びソフトウェアは、本契約に規定される条件のもとで使用許諾するものであり、販売されるものではなく、弊社および本取扱説明書及びソフトウェアの使用許諾権者は、使用許諾後も引き続きその知的所有権を保持します。<br />
3.本取扱説明書及びソフトウェアに対する知的所有権に関する表示を削除してはならないものとします。<br />
第3条　使用制限<br />
1.本取扱説明書及びソフトウェアの用途は、購入製品またはその添付ソフトウェアとともに使用することのみとします。<br />
2.お客様は、本ソフトウェアのソースコードを調べたり、逆アセンブル、逆コンパイル、リバースエンジニアリング、その他の修正を本ソフトウェアに加えることはできません。本ソフトウェアの一部または全部を利用した新しいソフトウェアの開発もこの規定により禁止されます。<br />
第4条　保証<br />
1.弊社は本取扱説明書及びソフトウェアに対していかなる保証も行いません。<br />
第5条　損害賠償<br />
1.弊社は、データの消失、業務の中断、逸失利益、精神的損害等を含め本取扱説明書及びソフトウェアの使用または使用不能に起因する直接的、間接的、特別、偶発的、結果的、その他いかなる損害にも、一切の責任を負いません。<br />
2.いかなる場合においても、弊社の責任の上限は、お客様が購入製品の対価として支払った金額とします。<br />
第6条　その他<br />
1.お客様は、本取扱説明書及びソフトウェアを日本国外に持ち出すことはできません。<br />
2.お客様が本契約のいずれかの規定に違反した場合、弊社はただちにお客様による本取扱説明書及びソフトウェアの使用を差止め、本契約を解除することができます。その場合、お客様は、ただちに本取扱説明書及びソフトウェアおよびその複製物のすべてを廃棄または抹消しなければなりません。<br />
3.本取扱説明書及びソフトウェアに表示されている著作権者も本契約について弊社と同じ権利を有するものとします。<br />
4.本契約に関わる紛争が発生した場合は、弊社の本店所在地を管轄する裁判所を専属的裁判所とします。<br />
以上
          </p>
          <p>
            <input type="checkbox" name="agree" value="1" required />
            &nbsp;&nbsp;利用規約に同意する
          </p>
          <p>
            <input type="submit" value="送信" className="submit_button" />
          </p>
          {contactForm}
        </form>
      </div>
    )
  }
}
